@import '~assets/scss/variables.scss';

@mixin media {
  width: 180px;
  height: 180px;
  border: 1px solid map-get($colors, 'black_ultra_light');
  margin-right: 10px;
  cursor: pointer;
  border-radius: 2px;
  margin-top: 10px;
}

.mediaModal {
  width: 1000px;
  height: 600px;
  max-width: 70vw;
  max-height: 50vh;
  .iconClose {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .modalHeader {
    padding: 32px 32px 0px 32px;
    max-height: 90px;
  }
  .modalFooter {
    border-top: 1px solid map-get($colors, 'black_medium');
    padding: 20px;
    max-height: 80px;
    justify-content: space-between;
  }
  .modalContent {
    max-height: calc(100% - 170px);
    padding: 10px 32px;
    display: flex;
    flex-wrap: wrap;
  }

  .uploadContainer {
    @include media;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0px;
  }
  .imageContainer {
    @include media;
    position: relative;
    & img {
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 2px;
    }
    .checkbox {
      position: absolute;
      bottom: 10px;
      left: 10px;
      background: #ffffff;
      border-radius: 2px;
      padding: 2px;
      background-color: #ffffff !important;
    }
    .checkStyle {
      color: map-get($colors, 'primary_pink');
    }
  }
}
