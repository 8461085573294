@import '~assets/scss/variables.scss';

.cardPadding {
  padding: 20px;
  min-height: 250px;
  .informationContainer {
    display: flex;
    justify-content: space-between;
  }
  .userInformation {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    .w_49 {
      width: 49%;
    }
    .textMargin {
      margin-left: 2%;
    }
  }
  .dateContainer {
    width: 25%;
  }
  .hideBorder {
    background: map-get($colors, 'black_light') !important;
    border-radius: 3px;
    overflow: hidden;

    &::before {
      border-bottom: none !important;
    }
    &::after {
      border-bottom: none !important;
    }
  }

  // device info
  .deviceInfoContainer {
    flex-direction: column;
    padding: 20px 0;
  }
  .iconAdd {
    color: map-get($colors, 'primary_blue');
  }

  //request image
  .cardHeader {
    border-bottom: 1px solid map-get($colors, 'black_medium');
  }

  .tabInsideWrapper {
    display: flex;
    flex-wrap: wrap;
    max-height: 470px;
    overflow-y: auto;
  }
  .tabPanel {
    padding: 24px 0;
    .imageContainer {
      max-width: 260px;
      margin-right: 30px;
      margin-top: 10px;
    }
    .imageStyle {
      height: 260px;
      width: 260px;
      margin-right: 30px;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
    }
    .imageWrapper {
      position: relative;
    }
    .toolsWrapper {
      position: absolute;
      bottom: 0px;
      right: 10px;
    }
  }

  //service provide

  .serviceNo {
    width: 8%;
  }
  .provideProduct {
    max-width: 300px !important;
    width: 25%;
    margin-right: 15px;
  }
  .descriptionStyle {
    max-width: 260px !important;
    width: 20%;
    overflow-x: auto !important;
    margin-right: 15px;
  }
  .quantity {
    width: 5%;
    margin-right: 15px;
  }
  .unitPrice {
    width: 15%;
    margin-right: 15px;
  }

  .color_primary {
    color: map-get($colors, 'primary_blue');
  }
  .borderBtn {
    border: 1px solid map-get($colors, 'primary_blue');
  }
}

.w_30 {
  width: 29% !important;
  margin-right: 1% !important;
}

.w_35 {
  width: 34% !important;
  margin-right: 1% !important;
}

.w_50 {
  width: 50% !important;
}

.w_10 {
  width: 10% !important;
}

.textColor {
  color: map-get($colors, 'black_pure') !important;
}
.labelColor {
  color: map-get($colors, 'black_dark') !important;
}

.newIcon {
  font-weight: 600 !important;
  color: map-get($colors, 'primary_blue') !important;
}
