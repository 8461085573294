@import '~assets/scss/variables.scss';

.categoryChip {
  margin: 2px 2px 2px 5px !important; 
  background-color: map-get($colors, 'black_light') !important;
  color: map-get($colors, 'black_pure') !important;
  &:hover {
    background-color: map-get($colors, 'black_pure') !important;
    color: map-get($colors, 'color_text_started') !important;
  }
}