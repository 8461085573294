@import '~assets/scss/variables.scss';

.btnCopy {
  background-color: map-get($colors, 'section-bg') !important;
  height: 32px;
  border-radius: 4px;
  margin-left: 8px !important;
  cursor: pointer;
  img {
    margin-right: 4px;
  }
  p {
    font-weight: 700 !important;
    color: map-get($colors, 'shade_grey_blue') !important;
  }
}