@import '~assets/scss/variables.scss';

.noCategory {
  min-height: 182px;
  border: 1px solid map-get($colors, 'black_medium');
  border-radius: 8px !important;
}

.categoryTag {
  min-height: 182px;
  border: 1px solid map-get($colors, 'black_medium');
  width: '100%';
  padding: 10px;
  border-radius: 8px;
  max-height: 400px !important;
  overflow-y: auto !important;
}

.addBtn {
  color: map-get($colors, 'general_blue') !important;
  font-weight: bold !important;
}

.shorterWrapper {
  min-height: 100px;
}