@import 'react-big-calendar/lib/sass/styles.scss';

.rbc-month-row {
  overflow: unset;
}


.rbc-time-slot {
  min-height: 32px;
  // border-top: 1px solid #e0e0e0;
}

.rbc-time-gutter .rbc-timeslot-group .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-day-slot .rbc-timeslot-group .rbc-time-slot {
  border-top: 1px solid #e0e0e0;
}

.rbc-month-view, .rbc-time-view {
  background-color: white;
  margin-top: 60px;
}

.rbc-time-view {
  background-color: white;
}

.rbc-today {
  // background-color: #FFD4D4;
}
.rbc-event-content {
  &:hover {
    opacity: 0.8;
  }
}

.rbc-header {
  vertical-align: middle;
}

.rbc-month-header {
  min-height: 40px;
  vertical-align: middle;
}

.rbc-time-header-cell {
  min-height: 40px;
}

.rbc-time-header-cell .rbc-header {
  min-height: 40px;
}
.rbc-calendar {
  height: calc(100vh - 100px);
  // position: relative;
}
.rbc-day-slot .rbc-event {
  border: none
}
.rbc-event-label, .rbc-allday-cell {
  display: none;
}
.rbc-time-content {
  border-top: none
}