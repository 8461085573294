@import '~assets/scss/variables.scss';

.card {
  padding: 16px;
  box-shadow: none !important;
  border-radius: 3px !important;
  cursor: pointer;
}

.tag {
  background-color: map-get($colors, 'section-bg');
  padding: 8px;
  margin-right: 4px;
}

.titleFilter {
  color: map-get($colors, 'shade_dark_grey') !important;
  font-weight: bold !important;
  font-family: 'DM Sans' !important;
}