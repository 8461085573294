@import '~assets/scss/variables.scss';

.toolBar_wrapper {
  // position: absolute;
  height: 60px;
}

.month_selector {
  background-color: map-get($colors, 'section-bg') !important;
  border-radius: 4px !important;
  max-height: 44px !important;
}

.btn_today {
  background-color: map-get($colors, 'section-bg') !important;
  box-shadow: unset !important;
}

.font_weight_700 {
  font-weight: 700 !important;
}

.text_margin {
  margin-right: 8px !important;
}

.text_responsive {
  font-size: 80% !important;
}

.btnSelectCategory {
  background-color: map-get($colors, 'section-bg') !important;
  max-width: 200px;
}

.textWrapper {
  text-overflow: ellipsis;
  max-width: 140px;
  white-space: nowrap;
  overflow-x: hidden;
}

@mixin status_badge {
  padding: 0px 2px;
  // display: flex;
  margin-bottom: 1px;
}

.lateEvent {
  @include status_badge;
  color: map-get($status, 'general_color');
  background-color: map-get($status, 'lateVisit_backgroundColor');
  border-left: 3px solid map-get($status, 'lateVisit_borderColor');
}

.unassignedEvent {
  @include status_badge;
  color: map-get($status, 'general_color');
  background-color: map-get($status, 'unassigned_backgroundColor');
  border-left: 3px solid map-get($status, 'unassigned_borderColor');
}

.onStandbyEvent {
  @include status_badge;
  color: map-get($status, 'general_color');
  background-color: map-get($status, 'onStandby_backgroundColor');
  border-left: 3px solid map-get($status, 'onStandby_borderColor');
}

.onTheWayEvent {
  @include status_badge;
  color: map-get($status, 'general_color');
  background-color: map-get($status, 'onTheWay_backgroundColor');
  border-left: 3px solid map-get($status, 'onTheWay_borderColor');
}

.arrivedEvent {
  @include status_badge;
  color: map-get($status, 'general_color');
  background-color: map-get($status, 'arrived_backgroundColor');
  border-left: 3px solid map-get($status, 'arrived_borderColor');
}

.startedEvent {
  @include status_badge;
  color: map-get($status, 'general_color');
  background-color: map-get($status, 'started_backgroundColor');
  border-left: 3px solid map-get($status, 'started_borderColor');
}

.completedEvent {
  @include status_badge;
  color: map-get($status, 'general_color');
  background-color: map-get($status, 'completed_backgroundColor');
  border-left: 3px solid map-get($status, 'completed_borderColor');
}

.cancelledEvent {
  @include status_badge;
  color: map-get($status, 'general_color');
  background-color: map-get($status, 'cancelled_backgroundColor');
  border-left: 3px solid map-get($status, 'cancelled_borderColor');
}

.eventContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.eventTitle {
  word-break: break-word;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
