@import '~assets/scss/variables.scss';

.header {
  background-color: map-get($colors, 'light_yellow');
  position: relative;
  height: 250px;
}

.avatar {
  height: 80px;
  width: 80px;
  border-radius: 12px;
  object-fit: contain;
  object-position: center;
}

.card {
  padding: 16px;
  box-shadow: none !important;
  border-radius: 3px !important;
}

.tag {
  background-color: map-get($colors, 'section-bg');
  padding: 8px;
  margin-right: 4px;
}

.floatingImg {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 250px;
  z-index: 0;
}

.cursor {
  cursor: pointer;
}

.greyColor {
  color: map-get($colors, 'shade_medium_grey') !important;
  font-weight: bold !important;
}

.floatingCircle {
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
}

.percentage {
  color: map-get($colors, 'general_green') !important;
  font-size: 3vw !important;
}

.lineChart {
  height: 100% !important;
}

.textGrey {
  color: map-get($colors, 'shade_dark_grey') !important;
}

.emptyWrapper {
  height: calc(90vh - 250px);
}

.textGrey {
  color: map-get($colors, 'shade_medium_grey') !important;
  font-weight: 700 !important;
}

.noDataTitle {
  color: map-get($colors, 'shade_grey_blue') !important;
}