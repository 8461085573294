@import '~assets/scss/variables.scss';

.headerWrapper {
  display: flex;
  align-items: center;
}

.deleteText {
  color: map-get($colors, 'general_red') !important;
  font-weight: bold !important;
}

.headerSpacing {
  padding: 22px 20px;
  border-bottom: 1px solid map-get($colors, 'black_medium');
}

.boldHeader {
  font-weight: bold !important;
}

.smallSpacing {
  padding: 20px 10px;
}

.horizontalSpacing {
  padding: 0px 14px;
}

.content {
  margin-top: 4px !important;
  font-weight: 700 !important;
}

.label {
  color: map-get($colors, 'shade_medium_grey') !important;
}

.confirmDelete {
  background-color: map-get($colors, 'general_red') !important;
  color: map-get($colors, 'light') !important;
}

.fontBoldWeight {
  font-weight: 700 !important;
}

.enterpriseUser {
  padding: 0px 20px;
}

.searchWrapper {
  display: flex;
  align-items: center;
  padding: 24px 0px;
}

.btnNew {
  box-shadow: none !important;
}

.newUserBtn {
  color: map-get($colors, 'general_blue') !important;
  font-weight: 700 !important;
}

.dialogClass {
  width: 70vw;
  min-width: 600px;
}

.borderBottom {
  border-bottom: 1px solid map-get($colors, 'black_medium');
}

.borderTop {
  border-top: 1px solid map-get($colors, 'black_medium');
  justify-content: flex-start !important;
  padding: 16px 24px !important;
}

.detailsContent {
  padding: 16px 24px !important;
}

.checkBoxColor {
  color: map-get($colors, 'primary_yellow') !important;
}

.createNewEnterprise {
  background-color: map-get($colors, 'primary_yellow') !important;
  font-weight: bold !important;
  width: 86px !important;
}

.phoneNumber {
  color: map-get($colors, 'shade_dark_grey') !important;
}

.avatar {
  height: 100px;
  width: 100px;
  border-radius: 12px;
  object-fit: contain;
  object-position: center;
}

.tag {
  background-color: map-get($colors, 'section-bg');
  padding: 8px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.app_bar {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: none !important;
  border: 1px solid rgba(33, 33, 33, 0.08);
}

.rootTabs {
  font-weight: 700 !important;
  padding: 0 !important;
}

.indicator {
  height: 2px !important;
  background-color: map-get($colors, 'primary_yellow') !important;
}

.cardWrapper {
  border: 1px solid rgba(33, 33, 33, 0.08);
  border-top: unset;
  box-shadow: unset !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

.btn_add_service {
  color: map-get($colors, 'general_blue') !important;
}