@import '~assets/scss/variables.scss';

.spacingVertical {
  padding: 32px 0px;
}

.uploadWrapper {
  margin-top: 16px !important;
  display: flex;
  img {
    margin-right: 8px;
  }
  p {
    color: map-get($colors, 'general_blue');
    font-weight: bold;
  }
}

.textWrapper {
  margin-top: 18px !important;
  width: 90%;
  .accepted {
    color: map-get($colors, 'shade_dark_grey') !important;
  }
}

.topSpacing {
  margin-top: 18px !important;
}

.buttonGroup {
  margin-top: 20px;
  width: 100%;
}

.noneTopMargin {
  margin-top: 0 !important;
}

.rightSpacing {
  padding: 0px 22px;
}

.uploadCursor {
  cursor: pointer;
}

.addressWrapper {
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.container {
  position: relative;
  height: 1px;
}

.poweredBy {
  color: map-get($colors, 'shade_medium_grey') !important;
}

.itemAddress {
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.addressList {
  max-height: 200px;
  overflow-y: auto;
}

.avatar {
  height: 80px;
  width: 80px;
  border-radius: 12px;
  object-fit: contain;
  object-position: center;
}