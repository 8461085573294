@import '~assets/scss/variables.scss';

.dialog_wrapper {
  height: 60vh;
  width: 40vw;
  max-width: 500px;
  max-height: 547px;
}

.title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid map-get($colors, 'section-bg');
}

.border_bottom {
  padding: 8px;
  border-bottom: 1px solid map-get($colors, 'section-bg');
}

.address {
  color: map-get($colors, 'shade_grey_blue') !important;
}
