@import '~assets/scss/variables.scss';

.recentJobs {
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.06) !important;
  overflow-y: hidden;
  position: relative;
  z-index: 1;
  .borderBottom {
    border-bottom: 1px solid map-get($colors, 'section-bg');
  }
}

.headerText {
  color: map-get($colors, 'shade_grey_blue') !important;
}

.menuPaper {
  padding: 8px !important;
}

.btnCopy {
  background-color: map-get($colors, 'section-bg') !important;
  height: 32px;
  border-radius: 4px;
  margin-left: 8px !important;
  cursor: pointer;
  img {
    margin-right: 4px;
  }
  p {
    font-weight: 700 !important;
    color: map-get($colors, 'shade_grey_blue') !important;
  }
}

.orderId {
  color: map-get($colors , "order_id" )
}

.viewAll {
  color: map-get($colors, "general_blue") !important;
}

.widgetNotRelate {
  color: map-get($colors, 'shade_medium_grey') !important;
}