@import '~assets/scss/variables.scss';

.grey_color {
  color: map-get($colors, 'shade_medium_grey') !important;
}

.btn_details {
  box-shadow: unset !important;
  background-color: map-get($colors, 'shade_light_grey') !important;
  height: 40px;
  border-radius: 8px !important;
}

.view_all_btn {
  color: map-get($colors, 'general_blue') !important;
}

.icon_btn {
  background-color: map-get($colors, 'shade_light_grey') !important;
  border-radius: 8px !important;
  height: 40px;
}

.border_bottom {
  border-bottom: 1px solid map-get($colors, 'section-bg') !important;
}

.current_visit_wrapper {
  background: radial-gradient(
    23.41% 82.14% at 14.73% 0%,
    #bbe4f8 0%,
    #7eccf9 73.56%
  );
  border-radius: 4px;
}

.check_btn {
  background-color: map-get($colors, 'color_text_completed') !important;
  color: map-get($colors, 'general_green') !important;
  box-shadow: unset !important;
}

.close_btn {
  background-color: map-get($colors, 'color_text_completed') !important;
  color: map-get($colors, 'general_red') !important;
  box-shadow: unset !important;
}

.dialog_edit_visit {
  width: 70vw;
  max-width: 693px !important;
}

.border_vertical {
  border-bottom: 1px solid map-get($colors, 'section-bg');
  border-top: 1px solid map-get($colors, 'section-bg');
}

.modal_title {
  color: map-get($colors, 'shade_grey_blue') !important;
  font-weight: 500 !important;
}

.complete_btn {
  background-color: map-get($colors, 'general_green') !important;
  color: map-get($colors, 'color_text_completed') !important;
  box-shadow: unset !important;
}

.cancel_btn {
  background-color: map-get($colors, 'general_red') !important;
  color: map-get($colors, 'color_text_completed') !important;
  box-shadow: unset !important;
}

.confirm_archived {
  background: map-get($colors, 'general_red') !important;
  color: map-get($colors, 'color_text_abandoned') !important;
  box-shadow: unset !important;
}

.deleteBtn {
  color: map-get($colors, 'general_red') !important;
}

.btn_copy_address{
  color: #0363f3;
  cursor: pointer;
}