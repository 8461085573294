.staffConfirmation {
  padding: 10px;
}
.pageBody {
  margin-top: 50px;
  text-align: center;
}

.headerLogo {
  width: 150px;
  padding-left: 40px;
  padding-top: 40px;
}

.text {
  line-height: 24px;
}

.logo {
  width: 100%;
}
.btnActions {
  padding-top: 20px;
}

.btnAction {
  margin: 5px !important;
}