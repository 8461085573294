@import '~assets/scss/variables.scss';

.customizeDialog {
  max-height: 600px;
  width: 70vw;
  overflow-y: auto;
}

.noAnnouncementsWrapper {
  height: calc(580px - 53px);
}

.noContent {
  color: map-get($colors, 'shade_medium_grey') !important;
}

.announcements {
  height: 100% !important;
}

.announcementsCard {
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.06) !important;
  overflow-y: hidden;
  position: relative;
  z-index: 1;
  min-height: 580px;
  padding: 0;
}

.announcementBody {
  max-height: 527px;
  overflow-y: auto;
}

.announcementBorderBottom {
  border-bottom: 1px solid map-get($colors, 'section-bg');
  height: 53px;
}

.timeSubtitle {
  color: map-get($colors, 'shade_dark_grey') !important;
}

.contentBody {
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
