@import '~assets/scss/variables.scss';

.dialogClass {
  width: 70vw;
  max-width: 800px !important;
  height: auto;
  min-height: 600px !important;
}

.selectedItem {
  background-color: map-get($colors, 'color_text_started');
  border-radius: 4px;
}

.item {
  color: map-get($colors, 'shade_medium_grey');
}

.sideBar {
  background-color: map-get($colors, 'side_bar_color');
  min-height: 600px !important;
}

.height_100 {
  height: 100%;
}

.jobDetailsTitle {
  color: map-get($colors, 'shade_medium_grey') !important;
  margin-bottom: 12px !important;
  font-family: DM Sans !important;
}

.cursor {
  cursor: pointer;
}

.contentDialog {
  max-height: 100%;
  overflow-y: auto;
}
