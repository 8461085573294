@import '~assets/scss/variables.scss';

.btnNew {
  background: map-get($colors, 'primary_yellow') !important;
  height: 40px;
  font-weight: bold;
}

.spacingRight {
  margin-right: 20px !important;
}

.textGrey {
  color: map-get($colors, 'shade_dark_grey') !important;
}

.chip {
  background-color: map-get($colors, 'shade_medium_grey');
  border-radius: 12px;
  min-width: 30px;
  padding: 2px 4px;
  margin-left: 8px !important;
  &:hover {
    background-color: map-get($colors, 'dark');
  }
}

.chipText {
  color: map-get($colors, 'color_text_started') !important;
}

.activeState {
  cursor: pointer;
}
