@import '~assets/scss/variables.scss';

.header_bg {
  background-image: url('../../../../../../../../assets/images/enterprise/job-header-bg.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.grey_text {
  color: map-get($colors, 'shade_dark_grey') !important
}