@import '~assets/scss/variables.scss';

.deleteReportDialog {
  width: 500px;
  max-width: 50vw;
  max-height: 30vh;
  border-radius: 12px;
  padding: 24px;
}
.sendReportDialog {
  width: 500px;
  min-height: 340px;
  max-width: 50vw;
  max-height: 30vh;
  border-radius: 12px;
  padding: 24px;
}
.dialogContent {
  padding: 24px 0px !important;
  overflow-x: hidden;
}

.checkboxRoot {
  margin: 0px;
  margin-top: 10px;
  display: flex;
}
.checkbox {
  padding: 0px;
  margin-right: 6px;
}
.checked {
  color: map-get($colors, 'primary_pink') !important;
}
.title2 {
  color: map-get($colors, 'black_dark') !important;
}
