@import '~assets/scss/variables.scss';

.btnNew {
  background-color: map-get($colors, 'primary_yellow') !important;
  height: 40px;
  font-weight: bold;
}


.btnFilter {
  background-color: rgba(0, 0, 0, 0.05) !important;
  height: 40px;
  font-weight: bold;
  margin-right: 10px !important;
}