.logo {
    max-width: 116px;
}

.container {
    padding: 32px;
}

.logout {
    position: relative;
    top: 300px;
    font-size : 18px;
    text-align: center;
}

.logout button {
    margin-top: 16px;
}