@import '~assets/scss/variables.scss';

.appBar {
  background-color: white !important;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25) !important;
  .space_between {
    justify-content: space-between;
  }
  .flex_end {
    justify-content: flex-end;
  }
  .defaultIconColor {
    color: map-get($colors, 'black_dark');
  }

  .dp_flex {
    display: flex;
    align-items: center;
  }
  .actionIcon {
    color: map-get($colors, 'primary_pink');
  }
  .btnSave {
    background-color: map-get($colors, 'primary_pink');
  }
  .borderLeft {
    border-left: 1px solid map-get($colors, 'black_medium');
  }
  .sendBtn {
    background: map-get($colors, 'primary_pink');
    color: white;
    margin-left: 10px;
    &:hover {
      background: map-get($colors, 'primary_pink');
    }
  }
}

.backBtn {
  color: map-get($colors, 'primary_blue') !important;
}
.divider {
  min-height: 44px !important;
  margin: 0px 10px !important;
  background-color: map-get($colors, 'black_medium') !important;
}
