@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-UltraLightItalic.woff2') format('woff2'),
        url('AvenirNext-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-MediumItalic.woff2') format('woff2'),
        url('AvenirNext-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-HeavyItalic.woff2') format('woff2'),
        url('AvenirNext-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-BoldItalic.woff2') format('woff2'),
        url('AvenirNext-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Regular.woff2') format('woff2'),
        url('AvenirNext-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Heavy.woff2') format('woff2'),
        url('AvenirNext-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-DemiBoldItalic.woff2') format('woff2'),
        url('AvenirNext-DemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-UltraLight.woff2') format('woff2'),
        url('AvenirNext-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Bold.woff2') format('woff2'),
        url('AvenirNext-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Medium.woff2') format('woff2'),
        url('AvenirNext-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-DemiBold.woff2') format('woff2'),
        url('AvenirNext-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Italic.woff2') format('woff2'),
        url('AvenirNext-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-bold-webfont.woff2') format('woff2'),
				url('open_sans/opensans-bold-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-bolditalic-webfont.woff2') format('woff2'),
				url('open_sans/opensans-bolditalic-webfont.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-extrabold-webfont.woff2') format('woff2'),
				url('open_sans/opensans-extrabold-webfont.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-extrabolditalic-webfont.woff2') format('woff2'),
				url('open_sans/opensans-extrabolditalic-webfont.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-italic-webfont.woff2') format('woff2'),
				url('open_sans/opensans-italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-light-webfont.woff2') format('woff2'),
				url('open_sans/opensans-light-webfont.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-lightitalic-webfont.woff2') format('woff2'),
			url('open_sans/opensans-lightitalic-webfont.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-regular-webfont.woff2') format('woff2'),
				url('open_sans/opensans-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-semibold-webfont.woff2') format('woff2'),
				url('open_sans/opensans-semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('open_sans/opensans-semibolditalic-webfont.woff2') format('woff2'),
				url('open_sans/opensans-semibolditalic-webfont.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}