@import "~assets/scss/variables.scss";

.companyImg {
  width: 76px;
  height: 76px;
  margin-right: 10px;
  border-radius: 4px;
}
.dp_flex {
  display: flex;
}