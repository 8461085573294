@import '~assets/scss/variables.scss';

.selectedBorder {
  border: 3px solid map-get($colors, 'general_blue');
  box-sizing: border-box;
}

.box_style {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.background_box {
  position: 'absolute';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.container {
  height: 100%;
  background-color: #F2F2F2;
}

.video_wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 50%;
}

.itemImg {
  margin-right: 8px;
  margin-bottom: 8px;
  width: 140px !important;
  height: 140px !important;
  overflow: hidden !important;
  border-radius: 2px;
  position: relative;
}

.filter_border {
  border-right: 1px solid #e2e2e2;
}

.selected_file_bg {
  background-color: map-get($colors, 'section-bg') !important;
}

.selected_btn {
  color: map-get($colors, 'shade_black') !important;
  border: 1px solid map-get($colors, 'shade_black') !important;
}

.unselect_btn {
  color: map-get($colors, 'shade_medium_grey') !important;
  border: 1px solid map-get($colors, 'shade_medium_grey') !important;
}

.download_btn {
  background-color: map-get($colors, 'primary_yellow') !important;
  box-shadow: unset !important;
}

.img_render {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

.video_container {
  width: inherit;
  height: inherit;
  position: relative;
}

.border_bottom {
  border-bottom: 1px solid map-get($colors, 'light_grey');
}

.select_btn {
  color: map-get($colors, 'general_blue') !important;
}

.absolute_checked {
  position: absolute;
  bottom: 9px;
  left: 9px;
}