@import '~assets/scss/variables.scss';

.btnSave {
  background-color: map-get($colors, 'primary_yellow') !important;
  color: map-get($colors, 'dark') !important;
}

.btnSaveDisabled {
  background-color: map-get($colors, 'black_medium') !important;
  color: map-get($colors, 'why_wm') !important;
}

.enterpriseInformation {
  margin-top: 20px;
}

.titleWrapper {
  padding: 12px 24px;
  border-bottom: 1px solid map-get($colors, 'black_medium');
}

.title {
  font-weight: bold !important;
}

.headerWrapper {
  display: flex;
  align-items: center;
}
