@import '~assets/scss/variables.scss';

.borderTop {
  border-top: 1px solid #e2e2e2;
}

.indicator {
  background-color: map-get($colors, 'primary_yellow') !important;
}

.total{
  background-color: #F0F0F0;
  color: #A2A2A2 !important;
  border-radius: 79px;
  padding: 0 10px;
  margin-left: 4px !important;
}
