@import '~assets/scss/variables.scss';

.request-details-page {
  padding: 20px;

  .head-details {

    .direction-link {
      display: inline;
    }

    .action {
      float: right;
      margin-top: -8px;

      .MuiButton-root {
        margin: 0 5px;
      }
    }
  }

  .body-content {

    border: 1px solid map-get($colors, grey);
    margin-top: 20px;
    border-radius: 5px;

    .basic-information {
      padding: 20px 30px 0;
      background-color: map-get($colors, section-bg);
      border-radius: 5px 5px 0 0;

      .header-info {
        display: flex;
        padding-bottom: 10px;

        .request-number {
          padding-bottom: 10px;
          padding-right: 10px;
        }

      }
      .request-info {
        border-top: 1px solid map-get($colors, grey);
        margin: 0;
        width: 100%;
        padding-top: 10px;

        .left-content {
          padding-left: 0;
          padding-top: 0;

          .contact {
            display: flex;
            flex-direction: row;
            padding-top: 15px;

            .property {
              width: 60%;
            }

            .contact-info {
              flex-grow: 1;
              padding-left: 10px;
            }

            .contact-info, .property{
              .title {
                padding-bottom: 5px;
              }
            }
          }
        }

        .right-content {
          background: white;

          .contact {
            padding-top: 10px;
            font-size: 14px;

            .item {
              display: flex;
              border-bottom: 1px solid map-get($colors, grey);
              padding: 8px 0;

              .type-name {
                width: 200px;
              }
            }
          }
        }
      }
    }

    .list-items {
      padding: 30px;

      .list-items-wraper {

        border: 1px solid map-get($colors, grey);
      }

      .section-header {
        background: map-get($colors, section-bg);
        height: 50px;
        line-height: 30px;
        padding: 10px;
        font-weight: 500;
        font-size: 20px;
        border-radius: 5px 5px 0 0;

      }

    }

    .list-visit {
      padding: 0 30px 30px;

      .list-visit-wraper {

        border: 1px solid map-get($colors, grey);
      }

      .section-header {
        background: map-get($colors, section-bg);
        height: 50px;
        line-height: 30px;
        padding: 10px;
        font-weight: 500;
        font-size: 20px;
        display: flex;
        flex-direction: row;
        border-radius: 5px 5px 0 0;

        .title {
          flex-grow: 1;
        }

        .add-visit-btn {
          span {
            height: 18px;
            line-height: 18px;
          }
        }
      }



    }
  }
}

.request-tabs-indicator {
  border-radius: 3px 3px 0px 0px;
  border: none;
}

.request-tabs-flex-container {
  border-bottom: 2px solid #F2F2F2;
}

.tab-total{
  background-color: #F0F0F0;
  color: #A2A2A2 !important;
  border-radius: 79px;
  padding: 0 10px;
  margin-left: 4px !important;
}