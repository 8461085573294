@import '~assets/scss/variables.scss';

.remove_color {
  color: map-get($colors, 'general_red') !important;
}

.label {
  color: map-get($colors, 'shade_medium_grey') !important;
}

.field_container {
  padding-top: 12px;
  border-top: 1px solid map-get($colors, 'shade_light_grey') !important;
  margin-bottom: 12px;
}

.field_container_no_order {
  padding-top: 12px;
  margin-bottom: 12px;
}

.btn_delete {
  margin-right: 8px !important;
  border-color: map-get($colors, 'general_red') !important;
  color: map-get($colors, 'general_red') !important;
}

.btn_save {
  background-color: map-get($colors, 'primary_yellow') !important;
  box-shadow: unset !important;
  min-width: 180px !important;
}

.underline {
  * {
    border-bottom: none !important;
  }

  *:before {
    border: none !important;
  }
}

.item_address {
  cursor: pointer !important;
  &:hover {
    background: #e2e2e2 !important;
  }
}

.address_wrapper {
  position: absolute !important ;
  padding: 4px;
  background: white;
  z-index: 10;
  box-shadow: 0 10px 30px 0 #e2e2e2;
  border-radius: 8px;
}

.search_results {
  max-height: 200px;
  overflow-y: auto;
}

.btn_action {
  border-color: map-get($colors, 'shade_grey_blue') !important;
}

.confirm_archived {
  background: map-get($colors, 'shade_grey_blue') !important;
  color: map-get($colors, 'color_text_abandoned') !important;
  box-shadow: unset !important;
}

.required {
  color: map-get($colors, 'general_red') !important;
}

.map_wrapper {
  min-height: 200px;
  margin-top: 8px !important;
}

.map_text {
  color: map-get($colors, 'general_blue') !important;
  margin-left: 4px !important;
}

.cursor {
  cursor: pointer;
}